// Generated by Framer (552ec80)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["iqVklFe4R", "s0BBWzoaJ"];

const serializationHash = "framer-hiCau"

const variantClassNames = {iqVklFe4R: "framer-v-1x01y5b", s0BBWzoaJ: "framer-v-irhify"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.2, ease: [0.12, 0.23, 0.5, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"desktop-dark": "s0BBWzoaJ", Desktop: "iqVklFe4R"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "iqVklFe4R"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "iqVklFe4R", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.footer {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1x01y5b", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"iqVklFe4R"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-4900f5da-6a86-4bcc-8a57-f16b741c2555, rgb(255, 255, 255))", ...style}} variants={{s0BBWzoaJ: {backgroundColor: "var(--token-94e2536a-f6a8-44b6-8659-4e4fb79fdfa4, rgb(16, 16, 22))"}}} {...addPropertyOverrides({s0BBWzoaJ: {"data-framer-name": "desktop-dark"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-hiCau.framer-14kfly6, .framer-hiCau .framer-14kfly6 { display: block; }", ".framer-hiCau.framer-1x01y5b { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; min-height: 561px; overflow: hidden; padding: 120px 80px 120px 80px; position: relative; width: 1740px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-hiCau.framer-1x01y5b { gap: 0px; } .framer-hiCau.framer-1x01y5b > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-hiCau.framer-1x01y5b > :first-child { margin-top: 0px; } .framer-hiCau.framer-1x01y5b > :last-child { margin-bottom: 0px; } }", ".framer-hiCau.framer-v-irhify.framer-1x01y5b { min-height: 680px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 561
 * @framerIntrinsicWidth 1740
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"s0BBWzoaJ":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramernQrGk4X3z: React.ComponentType<Props> = withCSS(Component, css, "framer-hiCau") as typeof Component;
export default FramernQrGk4X3z;

FramernQrGk4X3z.displayName = "footer";

FramernQrGk4X3z.defaultProps = {height: 561, width: 1740};

addPropertyControls(FramernQrGk4X3z, {variant: {options: ["iqVklFe4R", "s0BBWzoaJ"], optionTitles: ["Desktop", "desktop-dark"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramernQrGk4X3z, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})